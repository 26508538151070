import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1595.000000 1692.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1692.000000) scale(0.100000,-0.100000)">
<path d="M8 8465 l-3 -8455 7973 0 7972 0 0 8455 0 8455 -7970 0 -7970 0 -2
-8455z m8592 6690 c940 -113 1834 -581 2595 -1356 755 -770 1241 -1699 1404
-2684 43 -259 46 -318 46 -950 0 -589 -1 -615 -23 -760 -120 -767 -441 -1676
-756 -2135 -94 -138 -268 -299 -322 -300 -7 0 -24 39 -38 86 -30 95 -127 339
-265 662 -111 262 -141 343 -141 386 0 19 34 132 76 252 194 557 287 850 324
1014 53 238 83 736 61 1020 -60 768 -327 1536 -732 2102 -364 508 -854 919
-1419 1192 -314 151 -656 253 -1015 302 -182 25 -619 26 -795 1 -566 -79
-1042 -259 -1510 -572 -379 -254 -706 -567 -989 -950 -394 -532 -628 -1110
-718 -1775 -25 -190 -25 -746 0 -930 57 -404 139 -715 282 -1071 338 -837 952
-1573 1686 -2018 398 -242 825 -394 1279 -457 172 -24 538 -24 710 -1 408 56
761 173 1125 373 114 63 368 225 378 241 3 5 -30 91 -72 193 -42 102 -452
1088 -911 2193 -458 1104 -837 2004 -840 2000 -4 -5 -112 -258 -240 -563 -128
-305 -351 -836 -495 -1180 -528 -1255 -744 -1770 -851 -2023 l-108 -259 -31 6
c-52 11 -166 71 -260 137 -198 141 -508 492 -698 790 l-36 56 498 1109 c274
610 742 1654 1041 2319 298 666 546 1214 550 1218 10 11 78 -33 86 -56 5 -17
34 -18 548 -15 l542 3 49 32 c27 18 50 31 51 30 1 -1 270 -614 597 -1362 327
-748 1055 -2411 1617 -3695 562 -1284 1028 -2349 1036 -2366 l13 -31 -755 -7
c-416 -4 -758 -6 -760 -3 -3 2 -21 77 -41 166 -42 185 -29 173 -158 142 -121
-29 -204 -62 -515 -201 -435 -195 -616 -261 -855 -315 -263 -59 -276 -60 -865
-60 -565 0 -625 3 -870 46 -577 100 -1167 363 -1700 757 -663 491 -1214 1150
-1595 1912 -230 460 -392 967 -464 1456 -33 224 -41 395 -41 904 0 386 4 557
15 676 88 918 475 1819 1121 2613 66 80 205 233 309 339 330 337 638 584 1011
807 562 338 1171 536 1764 575 217 14 911 4 1070 -15z m-784 -10640 c197 -51
342 -197 396 -398 22 -83 0 -104 -168 -163 -114 -39 -125 -34 -184 81 -53 105
-93 139 -184 156 -97 18 -239 -10 -296 -57 -53 -45 -70 -91 -70 -194 0 -168
27 -189 380 -307 349 -117 473 -216 536 -428 21 -73 24 -278 5 -370 -35 -164
-135 -293 -283 -364 -111 -53 -211 -73 -374 -73 -328 1 -547 129 -639 375 -32
85 -32 127 -2 154 27 25 202 93 237 93 40 0 65 -25 88 -87 29 -78 71 -126 140
-160 54 -26 65 -28 187 -28 146 0 194 14 250 73 42 45 53 84 54 185 1 100 -11
137 -60 185 -45 44 -107 72 -332 148 -244 83 -326 126 -406 209 -76 79 -117
162 -132 270 -13 91 -6 225 16 310 72 276 321 425 681 409 56 -2 128 -11 160
-19z m-5786 -10 c17 -9 33 -28 39 -47 86 -270 581 -1945 581 -1966 0 -18 -10
-38 -26 -53 -24 -22 -33 -24 -147 -26 -84 -1 -132 3 -154 12 -36 15 -33 9
-116 305 l-43 155 -287 3 -287 2 -10 -22 c-5 -13 -33 -108 -61 -212 -66 -237
-66 -238 -182 -244 -172 -9 -237 13 -237 80 0 18 122 440 271 938 346 1155
314 1057 347 1074 39 21 271 22 312 1z m1871 -14 c29 -29 29 -31 29 -145 0
-107 -2 -117 -24 -143 l-24 -28 -206 -2 c-114 -2 -211 -3 -216 -3 -6 0 -11
-314 -12 -855 l-3 -854 -23 -23 c-21 -22 -31 -23 -152 -23 -121 0 -131 1 -152
23 l-23 23 -5 854 -5 855 -213 3 -214 2 -24 28 c-22 26 -24 36 -24 143 0 114
0 116 29 145 l29 29 602 0 602 0 29 -29z m432 17 c48 -25 46 6 47 -895 l0
-852 396 -3 396 -3 19 -24 c16 -20 19 -40 19 -143 0 -66 -5 -128 -10 -138 -20
-37 -73 -40 -589 -40 -513 1 -551 3 -571 40 -14 27 -14 2005 1 2031 6 11 20
24 32 29 33 13 235 12 260 -2z m1851 2 c14 -5 30 -18 36 -30 10 -18 530 -1743
579 -1921 35 -125 -4 -154 -201 -147 -94 3 -111 6 -129 25 -15 14 -40 86 -80
229 -33 114 -63 212 -67 216 -5 5 -136 7 -292 6 l-284 -3 -43 -155 c-79 -288
-80 -290 -116 -305 -22 -9 -70 -13 -154 -12 -113 2 -123 4 -147 26 -15 14 -26
35 -26 51 0 26 569 1934 591 1983 7 15 24 31 38 36 34 12 263 14 295 1z m3839
-18 c100 -27 166 -64 237 -129 66 -61 110 -137 142 -248 23 -79 23 -85 23
-645 0 -509 -2 -572 -18 -634 -42 -165 -133 -283 -268 -350 -118 -58 -168 -64
-587 -68 l-374 -3 -24 28 -24 28 0 995 0 996 30 30 30 30 378 -4 c347 -4 383
-6 455 -26z m1482 5 c23 -19 64 -147 321 -1002 161 -539 294 -990 294 -1002 0
-12 -11 -35 -24 -50 -24 -28 -26 -28 -145 -30 -134 -3 -176 7 -195 46 -7 14
-37 114 -66 221 -30 107 -59 198 -65 202 -5 4 -135 8 -288 8 l-277 0 -10 -22
c-6 -13 -35 -110 -64 -217 -36 -129 -61 -201 -75 -214 -18 -18 -36 -21 -135
-25 -138 -5 -181 6 -202 51 -15 31 -7 58 281 1022 164 545 302 996 308 1004
21 25 51 30 184 30 123 1 134 -1 158 -22z m1867 -5 c21 -20 23 -34 26 -128 7
-189 -1 -194 -281 -191 -195 2 -196 2 -202 -20 -3 -13 -5 -396 -5 -853 0 -549
-3 -837 -10 -849 -17 -32 -73 -44 -187 -39 -100 3 -106 5 -129 31 l-24 28 0
849 0 849 -216 3 -216 3 -24 28 c-22 26 -24 36 -24 146 0 121 5 137 45 160 14
7 197 10 622 8 l602 -2 23 -23z m911 17 c14 -6 30 -22 36 -36 19 -42 591
-1959 591 -1981 0 -11 -11 -34 -24 -49 -24 -28 -25 -28 -148 -28 -139 0 -173
6 -189 36 -6 11 -37 114 -68 227 l-58 207 -283 3 c-156 1 -288 -2 -292 -6 -5
-5 -35 -102 -67 -216 -39 -137 -65 -215 -79 -228 -17 -18 -37 -21 -129 -25
-138 -7 -188 6 -209 50 -14 31 -8 55 280 1013 162 540 299 992 306 1004 6 11
21 25 33 30 32 13 272 12 300 -1z"/>
<path d="M1856 3859 c-78 -273 -174 -624 -172 -626 2 -2 89 -2 193 -1 l190 3
-93 334 c-52 184 -96 336 -98 339 -3 2 -12 -20 -20 -49z"/>
<path d="M5985 3739 c-27 -96 -69 -248 -95 -339 l-46 -165 189 -3 c105 -1 191
-1 193 1 4 3 -171 632 -184 661 -6 14 -21 -26 -57 -155z"/>
<path d="M9490 3471 l0 -712 218 3 c210 3 218 4 260 28 27 15 54 42 70 70 l27
45 0 560 c0 560 0 560 -22 602 -48 88 -96 103 -350 110 l-203 6 0 -712z"/>
<path d="M11335 3899 c-6 -17 -185 -661 -185 -666 0 -1 86 -3 190 -3 135 0
190 3 190 11 0 14 -181 662 -186 668 -2 2 -6 -2 -9 -10z"/>
<path d="M14126 3899 c-3 -8 -44 -153 -91 -324 -47 -170 -88 -318 -91 -327 -5
-17 8 -18 185 -18 140 0 191 3 191 12 0 14 -180 661 -186 667 -2 2 -6 -2 -8
-10z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
